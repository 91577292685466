import { create } from '@storybook/theming'
// import logo from "./static/logo.png";

export default create({
  // UI
  appBg: '#FFF',

  appBorderColor: '#DADDE2',
  appBorderRadius: 6,

  appContentBg: '#F7F8F9',
  barBg: '#FFF',
  barSelectedColor: '#7D58FF',
  // Toolbar default and active colors
  barTextColor: '#1B2029',

  base: 'light',
  brandTitle: '@X/UI',

  colorPrimary: '#FF00D7',
  colorSecondary: '#7D58FF',

  // Typography
  fontBase:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  fontCode:
    "'Menlo', 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', 'Monaco', 'Consolas', monospace",
  // Form colors
  inputBg: '#FFF',

  inputBorder: '#DADDE2',
  inputBorderRadius: 6,
  inputTextColor: '#1B2029',
  // Text colors
  textColor: '#1B2029',

  textInverseColor: '#FFF',
  // brandUrl: "./",
  // brandImage: logo
})
